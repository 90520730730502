.footerInfo {
  color: rgba(0, 0, 0, 0.65);
  font-size: 12px;
}

@media only screen and (max-width: 1000px) {
  .messageCardBtns {
    flex-direction: column;
    align-items: flex-end;
  }
  .messageCardSection {
    flex-direction: column;
  }
}


@media only screen and (max-width: 600px) {
  .messageCardBtns {
    align-items: flex-start;
  }
  .messageCardContactSection {
    flex-direction: column;
    align-items: flex-start;
  }
}