.footerInfo {
  color: rgba(0, 0, 0, 0.65);
  font-size: 12px;
}

@media only screen and (max-width: 600px) {
  .supportCardContact {
    flex-direction: column;
    align-items: flex-start;
  }
  .supportCardContactSection {
    flex-direction: column;
    align-items: flex-start;
  }
}
