:root {
  --color-pink: #ee415f;
  --color-green: #58ce97;
  --color-orange: #fc7d4a;
  --color-red: #d14758;
  --color-pale-pink: #ef6e85;
  --color-coral: #f3907e;
  --color-peach: #ecb38d;
  --color-aqua: #a0ded0;
  --color-pale-aqua: #c0ebe1;
  --color-lightest-blue: #f8fcff;
  --color-blue: #4c88e9;
  --color-black: #242e39;
  --color-gray-1: #3b424b;
  --color-gray-2: #68707d;
  --color-gray-3: #949fab;
  --color-gray-4: #c7cdd4;
  --color-gray-5: #edf1f6;
  --color-gray-6: #f7f9fb;
  --color-white: #fff;
}

.button,
.button-save,
.button-remove,
.menu-button {
  display: flex;
  align-items: center;
  justify-content: center;

  height: 32px;

  margin: 0;
  padding: 0 8px;

  border: 0;
  border-radius: 4px;
  background: transparent;
  color: currentColor;

  font-family: inherit;
  font-size: inherit;
  line-height: 1;
  font-weight: inherit;

  white-space: nowrap;
  cursor: pointer;
}

.menu-button {
  width: 32px;
  padding: 0;
}

.button:hover,
.button.is-active,
.menu-button:hover,
.menu-button.is-active {
  background-color: var(--color-blue);
  color: var(--color-black);
}

.button:disabled,
.menu-button:disabled {
  color: var(--color-gray-6);
}

.button-save {
  background-color: var(--color-green);
  color: var(--color-white);
}

.button-remove {
  background-color: var(--color-red);
  color: var(--color-white);
}

.button-remove:disabled {
  background-color: var(--color-pale-pink);
}
.btn-save {
  margin-top: 10px;
}

.bubble-menu-dark {
  background-color: black;
  color: white;
  box-shadow: 10px 5px 5px white;
}

.bubble-menu-dark,
.bubble-menu-light {
  display: flex;
  align-items: center;
  gap: 8px;

  padding: 8px;

  border-radius: 4px;
}
