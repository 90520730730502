@font-face {
    font-family: "Open Sans";
    src: url("../public/fonts/OpenSans-VariableFont_wdth,wght.ttf");
}

html,
body,
#root {
    min-height: 100vh;
    height: 100%;
    flex: 1;
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

}

body .ant-checkbox-checked .ant-checkbox-inner {
    background-color: #3182CE !important;
    border-color: #3182CE !important;
}

body .ant-checkbox:not(.ant-checkbox-disabled):hover {
    border: none;
}

body .ant-checkbox-checked:after {
    border: none;
}

body .ant-collapse-header {
    align-items: center !important;
}


body .ant-layout {
    min-height: 100vh;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

::-webkit-scrollbar {
    height: 6px;
    width: 6px;
}

/* Track */
::-webkit-scrollbar-track {
    box-shadow: inset 0 0 3px transparent;
    border-radius: 5px;
}

/* Handle */
::-webkit-scrollbar-thumb {
    /* width: 5px; */
    background: grey;
    border-radius: 10px;
}

/*Обнуление*/
* {
    padding: 0;
    margin: 0;
    border: 0;
}

*,
*:before,
*:after {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

:focus,
:active {
    outline: none;
}

a:focus,
a:active {
    outline: none;
}

nav,
footer,
header,
aside {
    display: block;
}

html,
body {
    height: 100%;
    width: 100%;
    font-size: 100%;
    line-height: 1;
    font-size: 14px;
    flex: 1;
    -ms-text-size-adjust: 100%;
    -moz-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
}

input,
button,
textarea {
    font-family: inherit;
}

input::-ms-clear {
    display: none;
}

button {
    cursor: pointer;
}

button::-moz-focus-inner {
    padding: 0;
    border: 0;
}

a,
a:visited {
    text-decoration: none;
}

a:hover {
    text-decoration: none;
}

ul li {
    list-style: none;
}

img {
    vertical-align: top;
}

/*--------------------*/
body {
    background: #F1F4FA;
}

:global {
    .ant-card-head-title {
        color: #32325D;
    }
}

.ant-collapse > .ant-collapse-item:last-child {
    border-radius: unset;
}

.ant-collapse {
    border-radius: unset;
}

.ant-collapse-large > .ant-collapse-item > .ant-collapse-content > .ant-collapse-content-box {
    padding: 0;
}


input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.iconbtn {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
}


.leaflet-draw-tooltip {
    margin: 10px;
    width: 150px;
    background-color: #fff;
    padding: 10px;
    border-radius: 5px;
}


.mapDefault {
    margin: 1px 2px;
    border: 1px solid transparent;
    width: 100%;
    text-align: left;
    box-shadow: none;
}

.mapDefault:hover {
    border: 1px solid;
}

.mapDefault.active {
    border: 1px solid #F6F5F2;
    background: #F6F5F2;
}

.defaultIcons > svg {
    width: 100%;
    height: 100%;
}


.leaflet-container {
    width: 100%;
    border-radius: 20px;
}

.leaflet-container img.leaflet-tile {
    mix-blend-mode: unset;
    width: 100%;
}

.leaflet-touch .leaflet-control-layers,
.leaflet-touch .leaflet-bar {
    border: none;
    border-radius: 10px;
}


.defaultIcons > svg {
    width: 100%;
    height: 100%;
}

.tiptap {
    padding: 10px;
    padding-left: 25px;
    border-radius: 5px;
    border: 1px solid gainsboro;
    min-height: 200px;
}
