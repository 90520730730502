.dashedButton {
  :global {
    font-weight: 500;
  }
}

.cyan {
  border: 1px dashed rgb(94, 114, 228, 0.8);
  background-color: #f6f7fe;
  color: #5E72E4;
}

.green {
  border: 1px dashed #4AC93F;
  background-color: #F4FCF4;
  color: #4AC93F
;
}