.button {
  :global {
    border-radius: 10px;
    
    .ant-btn-primary {
      :disabled {
        color: white;
      }
    }
  }
}
